<template>
  <PageHeader
    pageTitle="Get In Touch!"
    pageSummary="Feel free to reach out to me via any of the means below!"
  />

  <div id="contactOptions">
    <h3 class="text-center text-2xl mb-6">
      Find me on Twitter at <a class="dwe-link" target="blank" href="https://twitter.com/dw3nt1">@dw3nt1</a>
    </h3>

    <h3 class="text-center text-2xl">
      Email me at <a class="dwe-link" href="mailto:contact@dualwieldent.com">contact@dualwieldent.com</a>
    </h3>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue'

export default {
  components: { PageHeader }
}
</script>

<style>

</style>