<template>
  <div class="mt-3 mb-10">
    <h1 class="dwe-color-gold text-center text-5xl mt-2 mb-4">{{ pageTitle }}</h1>
    <p v-if="pageSummary" class="text-center">{{ pageSummary }}</p>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      default: '',
      type: String
    },
    pageSummary: {
      default: '',
      type: String
    }
  }
}
</script>

<style>

</style>