<template>
  <section class="dwe-footer-border pt-5">
    <p class="text-center mb-3">
      <router-link  :to="{ name: 'Privacy' }" class="dwe-link">
        Privacy Policy
      </router-link>
    </p>

    <p class="text-center dwe-color-light-blue">&#169; {{ currentYear }} Dual Wield Entertainment</p>
  </section>
</template>

<script>
export default {
  setup() {
    const currentYear = new Date().getFullYear()
    return { currentYear }
  }
}
</script>

<style>
  .dwe-footer-border {
    border-top: solid 2px white;
  }
</style>