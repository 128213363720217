<template>
  <section class="mb-4 flex justify-center items-center dwe-bg-swords bg-contain md:bg-auto">
    <div class="text-center">
      <h1 class="dwe-title mb-0 mx-auto text-5xl md:text-7xl w-80 md:w-auto">
        <span class="dwe-caps-case">Dual </span>
        <span class="dwe-caps-case">Wield</span>
      </h1>
      <h2 class="text-xl md:text-4xl dwe-sub-title my-0">
        <span class="dwe-caps-case">Entertainment</span>
      </h2>
    </div>
  </section>

  <nav class="w-full flex flex-wrap justify-evenly border-l-0 border-r-0 dwe-nav items-center">
    <router-link 
      v-for="route in routes" 
      :key="route.name" 
      :to="route.path" 
      :class="route.name == router.currentRoute.value.name ? 'dwe-current-nav-item' : ''"
      class="text-white text-center text-2xl px-4 py-2 dwe-nav-item"
    >
      {{ route.name }}
    </router-link>
  </nav>
</template>

<script>
import router from '../router/index'

export default {
  setup() {
    const routes = [
      // router.resolve({ name: 'Home' }),
      router.resolve({ name: 'Games' }),
      // router.resolve({ name: 'Blog' }),
      router.resolve({ name: 'Contact' }),
    ]

    return { router, routes }
  }
}
</script>

<style scoped>
.dwe-bg-swords {
    min-height: 210px;
    background-image: url('../assets/web-banner.png');
    background-repeat: no-repeat;
    background-position: center;
}

.dwe-title {
    font-family: 'MontHeavy', sans-serif;
    color: #FFC600;
    text-shadow: black 0px 0px 10px;
    line-height: 4rem;
}

.dwe-sub-title {
    font-family: 'MontHeavy', sans-serif;
    color: #A2D6F9;
    text-shadow: black 0px 0px 10px;
    letter-spacing: 0.75rem;
}

.dwe-caps-case {
    text-transform: uppercase;
    display: inline-block;
}

.dwe-caps-case::first-letter {
    font-size: 125%;
}

.dwe-nav {
    border: #FFC600 2px solid;
    border-left: none;
    border-right: none;
}

.dwe-nav-item {
    font-family: 'Mont', 'sans-serif';
    font-weight: bold;
    color: white;
}

.dwe-nav-item:hover {
    color: #FFC600;
}
</style>