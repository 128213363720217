<template>
  <div class="mb-10 flex justify-between items-center">
    <h1 class="text-2xl">Modify Blog Post</h1>
    <router-link
      :to="{ name: 'BlogPreview', params: { postId: state.currentPostId } }"
      class="mx-2 py-1 px-3 border rounded dwe-bg-blue"
    >
      Preview
    </router-link>
  </div>

  <form @submit.prevent="submtBlogPostForm()">
    <input type="hidden" v-model="state.currentPostId" />

    <div class="m-2">
      <label>Title</label>
      <input 
        type="text"
        v-model="postTitle"
        class="w-full p-2 rounded border dwe-color-navy"
        placeholder="Title (eg: Light Finder - GGJ 2021)"
      />
      <p class="mx-2 my-1 text-blue-300 opacity-60"><span class="font-bold text-white">Slug:</span> {{ state.formData.slug }}</p>
    </div>

    <div class="m-2">
      <label>Author</label>
      <input
        type="text"
        v-model="state.formData.author"
        class="w-full p-2 rounded border text-gray-700 opacity-60"
        readonly="readonly"
      />
    </div>

    <div class="m-2">
      <label>Tags</label>
      <input
        type="text"
        v-model="postTags"
        class="w-full p-2 rounded border dwe-color-navy"
        placeholder="Tags (eg: games, dev, ggj2021)"
      />
    </div>

    <div class="m-2">
      <label>Thumbnail</label>
      <input
        type="text"
        v-model="state.formData.thumbnail"
        class="w-full p-2 rounded border dwe-color-navy"
        placeholder="Image link"
      />
      <p class="text-sm dwe-color-light-blue">Ideal image size: 150px (w) by 150px (h)</p>
    </div>

    <div class="mx-2 my-4">
      <label>Summay</label>
      <article>
        <textarea v-model="state.formData.summary" class="dwe-color-navy summaryEditor rounded p-1"></textarea>
      </article>
    </div>

    <div class="mx-2 my-4">
      <label>Content</label>
      <article>
        <textarea v-model="postMarkdown" class="dwe-color-navy markdownEditor rounded p-1"></textarea>
      </article>
      <a target="_blank" class="w-full text-right underline dwe-color-light-blue" href="https://www.markdownguide.org/cheat-sheet/">Markdown cheatsheet</a>
    </div>

    <div class="mx-2 my-4">
      <label>Markdown Preview</label>
      <article id="markdownPreview" class="w-full markdownEditor border rounded p-1" v-html="postContentPreview"></article>
    </div>

    <div class="m-2">
      <label>
        <input v-model="state.formData.published" type="checkbox">
        <span class="ml-2">Published</span>
      </label>
    </div>

    <div class="m-2">
      <input 
        type="submit"
        :value="submitText"
        :disabled="formSubmitting"
        :class="formSubmitting ? 'bg-green-900 text-gray-500 border-gray-500' : 'bg-green-700 text-white border-white'"
        class="w-full p-2 rounded border font-bold"
      />
    </div>
  </form>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import marked from 'marked'
import DOMPurify from 'dompurify'

import store from '../../../store/index'
import { postsRef } from '../../../utilities/firebase'

export default {
  setup() {
    const route = useRoute()
    const formSubmitting = ref(false)
    const submitText = ref('Save Post')
    const postTitle = ref( null )
    const postTags = ref( [] )
    const postMarkdown = ref('')
    const postContentPreview = ref('')
    const postContentTimer = ref(null)
    const state = reactive({
      currentPostId: route.params.postId ? route.params.postId : null,
      formData: {
        title: "",
        author: store.state.authUser.email,
        slug: "",
        tags: [],
        thumbnail: "",
        summary: "",
        content: "",
        published: false
      }
    })

    const postTitleWatcher = watch(postTitle, (newVal) => {
      state.formData.title = newVal
      state.formData.slug = slugify(newVal)
    })

    const postTagsWatcher = watch(postTags, (newVal) => {
      state.formData.tags = newVal.replace(/ +/g, '').split(",")
    })

    const postMarkdownWatcher = watch(postMarkdown, (newVal) => {
      state.formData.content = newVal
      clearTimeout(postContentTimer.value)
      postContentTimer.value = setTimeout(() => {
        var contentHtml = marked(newVal)
        postContentPreview.value = DOMPurify.sanitize( contentHtml , {USE_PROFILES: {html: true}} );
      }, 500)
    })

    function submtBlogPostForm() {
      updateSubmitButton("Saving...", true)

      var postData = { ...state.formData }
      postData.updated = new Date()

      if(state.currentPostId) {
        postsRef.doc(state.currentPostId).set(postData).then(() => {
          updateSubmitButton("Submit", false)
        }).catch(() => {
          //TODO: better error handling? accept the err variable?
          // TODO: reset submit button?
        })
      } else {
        postData.created = new Date()
        postsRef.add(postData).then((res) => {
          state.currentPostId = res.id
          state.formData.created = new Date()
          updateSubmitButton("Submit", false)
        }).catch(() => {
          //TODO: better error handling? accept the err variable?
          // TODO: reset submit button?
        })
      }
    }

    function updateSubmitButton(text, isSubmitting) {
      submitText.value = text
      formSubmitting.value = isSubmitting
    }

    function updatePostContent(data) {
      state.formData.content = data
    }

    function slugify(string) {
      return string.trim().toLowerCase().replace(/ +/g, '_')
    }

    onMounted(async () => {
      if(state.currentPostId) {
        const doc = await postsRef.doc(state.currentPostId).get()
        state.formData = { ...doc.data() }
        postTitle.value = state.formData.title
        postTags.value = state.formData.tags.join(', ')
        postMarkdown.value = state.formData.content
      }
    })

    return { state, updatePostContent, submtBlogPostForm, postTitle, postTitleWatcher, postTags, postTagsWatcher, postMarkdown, postMarkdownWatcher, postContentPreview, formSubmitting, submitText }
  }
}
</script>

<style>
#markdownPreview h1 {
  font-size: 2rem;
}

#markdownPreview h2 {
  font-size: 1.75rem;
}

#markdownPreview img {
  margin: 0 auto;
  max-height: 22rem;
}

#markdownPreview p {
  margin: 0  0 1.5rem;
}

#markdownPreview a {
  color: #94C3E3;
  text-decoration: underline;
}

.summaryEditor {
  width: 100%;
  height: 150px;
  overflow-y: scroll;
}

.markdownEditor {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
}
</style>