<template>
  <h3 v-if="!pageLoaded" class="text-white text-center text-3xl">Loading...</h3>
  <div v-else id="postWrap">
    <h1 class="my-4 text-5xl dwe-color-gold">{{ post.title }}</h1>
    <div class="flex justify-between items-center">
      <p class="dwe-color-light-blue text-sm">{{ postCreated }}</p>
    </div>

    <div id="postContentWrap" class="my-7" v-html="postContent"></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import marked from 'marked'
import DOMPurify from 'dompurify'

import router from '../router/index'
import { postsRef } from '../utilities/firebase'
import useBlogPostDateFormatter from '../utilities/blogPostDateFormatter'

export default {
  props: {
    postId: String,
    postSlug: String
  },
  setup(props) {
    const pageLoaded = ref(false)
    const formatDateForPost = useBlogPostDateFormatter()
    const postId = ref(props.postId)
    const postSlug = ref(props.postSlug)
    const post = ref({})
    const postCreated = ref(null)
    const postContent = ref('')

    async function loadPost() {
      var postDoc = null;
      if(postId.value) {
        postDoc = await postsRef.doc(postId.value).get()
      } else if(postSlug.value) {
        var snapshot = await postsRef.where('slug', '==', postSlug.value).limit(1).get()
        if(snapshot.docs.length > 0) {
          postDoc = snapshot.docs[0]
        }
      }

      if(!postDoc) {
        router.push({ name: 'Blog' })
      } else {
        post.value = postDoc.data()
        postCreated.value = formatDateForPost(new Date(post.value.created.seconds * 1000))
        var contentHtml = marked(post.value.content)
        postContent.value = DOMPurify.sanitize( contentHtml , {USE_PROFILES: {html: true}} );
        
        pageLoaded.value = true
      }
    }

    onMounted(async () => {
      loadPost()
    })

    return { post, postCreated, postContent, pageLoaded }
  }
}
</script>

<style>
.dwe-post-title {
  font-size: 2.75rem;
}

#postContentWrap h1 {
  font-size: 2rem;
  color: #FFC600;
}

#postContentWrap h2 {
  font-size: 1.75rem;
}

#postContentWrap img {
  margin: 0 auto;
  max-height: 22rem;
}

#postContentWrap p {
  margin: 0  0 1.5rem;
}

#postContentWrap a {
  color: #94C3E3;
  text-decoration: underline;
}

#postContentWrap ol, #postContentWrap ul {
  margin: 0 3rem 1.5rem;
  padding: 0;
}

#postContentWrap ol {
  list-style: decimal;
}
</style>