export default function useBlogPostDateFormatter() {
  function getLocale() {
    try {
      return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
    } catch (error) {
      return "en-US"  // in case this breaks just use US
    }
  }

  function formatDateForPost(date) {
    var formatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    }
  
    return date.toLocaleString(getLocale(), formatOptions)
  } 

  return formatDateForPost
}