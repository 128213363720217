<template>
<div class="mb-10 flex justify-between items-center">
    <h1 class="text-2xl">Blog Posts</h1>

    <router-link 
      :to="{ name: 'BlogModify' }"
      class="px-4 py-2 border rounded dwe-bg-blue"
    >
      Create Post
    </router-link>
  </div>

  <table class="w-full my-4">
    <thead>
      <tr>
        <th>Title</th>
        <th>Author</th>
        <th>Tags</th>
        <th class="w-1/6">Published</th>
        <th class="w-1/6">Created</th>
        <th class="w-1/6">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(post, postId) in state.posts" :key="postId">
        <td class="px-4 py-2 text-center">
          <router-link
            :to="{ name: 'BlogPreview', params: { postId: postId } }"
            class="underline"
          >
            {{ post.title }}
          </router-link>
        </td>
        <td class="px-4 py-2 text-center">{{ post.author }}</td>
        <td class="px-4 py-2 text-center">{{ post.tags.join(", ") }}</td>
        <td class="px-4 py-2 text-center">
          {{ post.published ? 'Yes' : 'No' }}
        </td>
        <td class="px-4 py-2 text-center">{{ post.created }}</td>
        <td class="px-4 py-2 text-center">
          <div class="flex justify-between items-center">
            <router-link
              :to="{ name: 'BlogPreview', params: { postId: postId } }"
              class="mx-2 py-1 px-3 border rounded dwe-bg-blue"
            >
              Preview
            </router-link>
            <router-link
              :to="{ name: 'BlogModify', params: { postId: postId } }"
              class="mx-2 py-1 px-3 border rounded bg-yellow-600"
            >
              Edit
            </router-link>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { postsRef } from '../../../utilities/firebase'
import useBlogPostDateFormatter from '../../../utilities/blogPostDateFormatter'

export default {
  setup() {
    const formatDateForPost = useBlogPostDateFormatter()
    const state = reactive({
      posts: {}
    })

    onMounted(async () => {
      const snapshot = await postsRef.orderBy('created', 'desc').limit(10).get()
      snapshot.forEach((doc) => {
        state.posts[doc.id] = doc.data()
        var formattedCreated = formatDateForPost(new Date(state.posts[doc.id].created.seconds * 1000))
        state.posts[doc.id].created = formattedCreated
      })
    })

    return { state }
  }
}
</script>

<style>

</style>