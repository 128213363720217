import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

import store from '../store/index'

import Site from '../layouts/Site'
import Admin from '../layouts/Admin'

// import About from '../pages/About'
import GameApp from '../pages/GameApp'
// import Blog from '../pages/Blog'
// import BlogPost from '../pages/BlogPost'
import Contact from '../pages/Contact'
import Privacy from '../pages/Privacy'

import Login from '../pages/admin/Login'
import Dashboard from '../pages/admin/Dashboard'
import Blogs from '../pages/admin/Blog'
import BlogIndex from '../pages/admin/blogs/Index'
import BlogModify from '../pages/admin/blogs/Modify'
import BlogPreview from '../pages/admin/blogs/View'
import Projects from '../pages/admin/Projects'

const routes = [
  { name: 'Site', path: '/', component: Site, children: [
    // { name: 'Home', path: '', component: About },
    { name: 'Home', path: '', redirect: 'games' },
    { name: 'Games', path: 'games', component: GameApp },
    // { name: 'Blog', path: 'blog', component: Blog},
    // { name: 'BlogPost', path: 'blog/:slug', component: BlogPost },
    { name: 'Contact', path: 'contact', component: Contact },
    { name: 'Privacy', path: 'privacy', component: Privacy }
  ]},

  { name: 'Admin', path: '/dw3-admin', component: Admin, children: [
    { name: 'Dashboard', path: '', component: Dashboard, meta: { middleware: 'auth' } },
    { name: 'Blogs', path: 'blogs', component: Blogs, meta: { middleware: 'auth' }, children: [
      { name: 'BlogIndex', path: '', component: BlogIndex, meta: { middleware: 'auth' } },
      { name: 'BlogModify', path: 'modify/:postId?', component: BlogModify, meta: { middleware: 'auth' } },
      { name: 'BlogPreview', path: 'preview/:postId', component: BlogPreview, meta: { middleware: 'auth' } }
    ] },
    { name: 'Projects', path: 'projects', component: Projects, meta: { middleware: 'auth' } },
    { name: 'Login', path: 'login', component: Login },
  ] },

  { name: '404', path: '/:catchAll(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _from, next) => { 
  if(to.meta.middleware) {
    const middleware = require(`../middleware/${to.meta.middleware}`)
    if(middleware) {
      middleware.default(next, store)
    } else {
      next()
    }
  } else {
    next()
  }
})

trackRouter(router)

export default router
