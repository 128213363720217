<template>
  <AdminHeader />

  <div class="mx-3">
    <router-view />
  </div>
</template>

<script>
import AdminHeader from '../layouts/AdminHeader'

export default {
  components: { AdminHeader }
}
</script>

<style>

</style>