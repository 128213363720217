<template>
  <nav v-if="isLoggedIn" class="w-full flex justify-evenly dwe-bg-light-blue rounded mb-4">
    <router-link 
      v-for="route in routes" 
      :key="route.name" 
      :to="route.path" 
      :class="route.name == router.currentRoute.value.name ? 'dwe-current-nav-item' : ''"
      class="px-4 py-2 dwe-admin-nav-item"
    >
      {{ route.name }}
    </router-link>

    <router-link to="/" class="px-4 py-2 dwe-admin-nav-item">Site</router-link>
    <button @click="logout()" class="px-4 py-2 dwe-admin-nav-item">Logout</button>
  </nav>

  <div v-if="flashMessage.length" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-pink-500">
    <span class="inline-block align-middle mr-8">
      {{ flashMessage }} 
    </span>
    <button @click="closeFlash()" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-5 mr-6 outline-none focus:outline-none">
      <span>×</span>
    </button>
  </div>
</template>

<script>
import { computed, ref } from 'vue'

import router from '../router/index'
import store from '../store/index'
import firebase from '../utilities/firebase'

export default {
  setup() {
    const isLoggedIn = computed(() => { return store.state.isLoggedIn })

    let flashMessage = ref("")

    const routes = [ 
      router.resolve({ name: 'Dashboard' }), 
      router.resolve({ name: 'Blogs' }),
      router.resolve({ name: 'Projects' }) 
    ]

    function logout() {
      firebase.auth().signOut().then(() => {
        router.push({ name: 'Login' })
      }).catch(() => {
        flashMessage.value = "Error logging out!"
        // TODO: log error to server?
      });
    }

    function closeFlash() {
      flashMessage.value = ""
    }

    return { router, routes, isLoggedIn, store, logout, closeFlash, flashMessage }
  }
}
</script>

<style>
.dwe-admin-nav-item {
  color: #072A66;
  font-family: 'MontHeavy', 'sans-serif';
}

.dwe-admin-nav-item:hover {
  color: #1460A1;
  text-decoration: underline;
}
</style>