<template>
  <PageHeader
    pageTitle="Games"
    pageSummary="Check out some of my past projects! The titles range from small personal projects to game jam entries."
  />

  <h3 v-if="!pageLoaded" class="text-white text-center text-3xl">Loading...</h3>
  <div v-else>
    <div v-if="Object.keys(projects).length <= 0">
      <h3 class=" text-2xl text-center">Cooming soon!</h3>
    </div>
    <div v-else class="mx-8 flex flex-wrap justify-center">
      <div class="w-full lg:w-1/2 mt-6 mb-4" v-for="(project, id) in projects" :key="id">
        <a target="_blank" :href="project.link" class="dwe-project-link dwe-color-gold">
          <img :src="project.image" class="m-auto rounded-xl dwe-project-img" />
          <h3 class="text-3xl text-center my-2">{{ project.name }}</h3>
        </a>
        <p class="text-center pb-4 px-4">{{ project.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { projectsRef } from '../utilities/firebase'

import PageHeader from '../components/PageHeader.vue'

export default {
  components: { PageHeader },
  setup() {
    const pageLoaded = ref(false)
    const projects = ref({})

    async function loadProjects() {
      projectsRef.orderBy('created', 'desc').get().then((snapshot) => {
        snapshot.forEach((doc) => {
          projects.value[doc.id] = doc.data()
        })
      }).catch(() => {
        projects.value = {}
      }).finally(() => {
        pageLoaded.value = true
      })
    }

    onMounted(async () => {
      loadProjects()
    })

    return { pageLoaded, projects }
  }
}
</script>

<style>
.dwe-project-link:hover {
  text-decoration: underline;
}

.dwe-project-img {
  border-width: 2px;
  border-color: #FFC600;
  height: 250px;
  width: 350px;
}
</style>