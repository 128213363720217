import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag-next'

import './assets/styles.css'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueGtag, {
  property: { id: process.env.VUE_APP_FB_MEASUREMENT_ID }
})
app.mount('#app')
