<template>
  <PageHeader
    pageTitle="Privacy Policy"
  />

  <div id="privacyPolicyWrap">
    <h3 class="text-3xl text-center">Version 1.0</h3>
    <h4 class="text-center italic">Last Updated: November 1st, 2021</h4>

    <section id="privacyData" class="mt-6">
      <p class="text-center">Dual Wield Entertainment games do not collect any of your data.</p>
      <p class="text-center mt-3">We have no databases to store the information on anyway. Even if someone were to ask for your information, we would have none to give them.</p>
    </section>
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue'

export default {
  components: { PageHeader }
}
</script>

<style>

</style>