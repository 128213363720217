import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: false,
    authUser: {}
  },
  mutations: {
    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },

    setAuthUser(state, user) {
      state.authUser = user
    }
  }
})
