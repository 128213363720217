<template>
  <h1 class="mb-4 text-2xl font-bold">Dw3nt Admin Dashboard</h1>

  <p class="mb-4">This is where I'd put some stuff. IF I HAD SOME!</p>
</template>

<script>
export default {

}
</script>

<style>

</style>