// auth reference: https://firebase.google.com/docs/web/setup?authuser=0#node.js-apps
// real time DB ref: https://firebase.google.com/docs/database/web/read-and-write

import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
  
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,  
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()
export const projectsRef = db.collection('projects')
export const postsRef = db.collection('posts')

export default firebase