<template>
  <div class="lg:container dwe-containter dwe-bg-navy mx-auto max-w-4xl">
    <router-view />

    <AppFooter />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import firebase from './utilities/firebase'

import AppFooter from './layouts/AppFooter'

export default {
  components: { AppFooter },
  setup() {
    const store = useStore()

    onMounted(() => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          store.commit('setIsLoggedIn', true);
          store.commit('setAuthUser', user)
        } else {
          store.commit('setIsLoggedIn', false);
          store.commit('setAuthUser', {})
        }
      })
    })
  }
}
</script>
