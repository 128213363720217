<template>
  <h1 class="mb-4 text-2xl">Projects</h1>

  <div class="w-full text-right">
    <button @click="showAddModal()" class="px-4 py-2 border rounded dwe-bg-blue">
      Add Project
    </button>
  </div>
  
  <table class="w-full my-4">
    <thead>
      <tr>
        <th class="w-1/5">Name</th>
        <th>Type</th>
        <th class="1/3">Description</th>
        <th class="w-1/6">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(project, projectId) in state.projects" :key="projectId">
        <td class="px-4 py-2 text-center">
          <a class="underline" target="_blank" :href="project.link">{{ project.name }}</a>
        </td>
        <td class="px-4 py-2 text-center">{{ project.type }}</td>
        <td class="px-4 py-2 text-center">
          {{ project.description.length &lt; 50 ? project.description : project.description.substring(0, 50) + "..." }}
        </td>
        <td class="px-4 py-2 text-center">
          <button @click="editProject(projectId)" class="mx-2 py-1 px-3 border rounded bg-yellow-600">
            Edit
          </button>
          <button @click="showDeleteModal(projectId)" class="mx-2 py-1 px-2 border rounded bg-red-900">
            X
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <section v-if="modifyModal">
    <div @click="closeModifyModal()" class="z-20 h-screen w-screen bg-gray-600 fixed top-0 left-0 opacity-50"></div>

    <div class="absolute inset-0">
      <div class="flex h-full">
        <div class="z-30 m-auto border rounded dwe-bg-blue p-4 shadow-md w-1/3">
          <div class="p-2">
            <h1 class="text-2xl text-center">Configure Project</h1>

            <form @submit.prevent="submitProjectForm()">
              <input type="hidden" v-model="state.currentProjectId" />

              <div class="m-2">
                <label>Name</label>
                <input 
                  type="text"
                  v-model="state.formData.name"
                  class="w-full p-2 rounded border dwe-color-navy"
                  placeholder="Name (eg: Space Escape)"
                />
              </div>

              <div class="m-2">
                <label>Type</label>
                <select v-model="state.formData.type" class="w-full p-2 rounded border bg-white dwe-color-navy">
                  <option value="game">Game</option>
                  <option value="app">App</option>
                </select>
              </div>

              <div class="m-2">
                <label>Image URL</label>
                <input 
                  type="url"
                  v-model="state.formData.image"
                  class="w-full p-2 rounded border dwe-color-navy"
                  placeholder="Image link"
                />
                <p class="text-sm dwe-color-light-blue">Ideal image size: 350px (w) by 250px (h)</p>
              </div>

              <div class="m-2">
                <label>Store Link</label>
                <input 
                  type="url"
                  v-model="state.formData.link"
                  class="w-full p-2 rounded border dwe-color-navy"
                  placeholder="Store page"
                />
              </div>

              <div class="m-2">
                <label>Description</label>
                <textarea
                  v-model="state.formData.description"
                  class="w-full p-2 rounded border dwe-color-navy"
                ></textarea>
              </div>

              <div class="m-2">
                <input 
                  type="submit"
                  class="w-full p-2 rounded border bg-green-900 font-bold"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="deleteModal">
    <div @click="closeDeleteModal()" class="z-20 h-screen w-screen bg-gray-600 fixed top-0 left-0 opacity-50"></div>

    <div class="absolute inset-0">
      <div class="flex h-full">
        <div class="z-30 m-auto border rounded dwe-bg-blue p-4 shadow-md w-1/3">
          <div class="p-2">
            <h1 class="text-2xl text-center">{{ state.formData.name }}</h1>
            <p class="text-center my-8">Are you sure you want to delete this project?</p>
            <div class="flex items-center justify-between">
              <button @click="deleteProject()" class="w-1/2 mx-4 p-2 rounded border bg-red-900 font-bold">Delete</button>
              <button @click="closeDeleteModal()" class="w-1/2 mx-4 p-2 rounded border bg-blue-400 font-bold">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import { projectsRef } from '../../utilities/firebase'

export default {
  setup() {
    const modifyModal = ref( false )
    const deleteModal = ref( false )
    const defaultFormData = {
      name: "",
      description: "",
      link: "",
      type: "",
      image: "",
    }
    const state = reactive({
      currentProjectId: null,
      projects: {},
      formData: defaultFormData
    })

    async function submitProjectForm() {
      var projectData = { ...state.formData }
      
      projectData.updated = new Date()
      if(state.currentProjectId) {
        projectsRef.doc(state.currentProjectId).set(projectData).then(() => {
          modifyModal.value = false
          state.projects[state.currentProjectId] = projectData
          state.currentProjectId = false

          emptyFormData()
        }).catch(() => {
          //TODO: better error handling? accept the err variable?
        })
      } else {
        projectData.created = new Date()
        projectsRef.add(projectData).then((res) => {
          state.projects[res.id] = projectData
          modifyModal.value = false

          emptyFormData()
        }).catch(() => {
          //TODO: better error handling? accept the err variable?
        })
      }
    }

    function emptyFormData() {
      state.formData = { ...defaultFormData }
    }

    function editProject(projectId) {
      state.currentProjectId = projectId
      state.formData = { ...state.projects[projectId] }
      modifyModal.value = true
    }

    function deleteProject() {
      let projectId = state.currentProjectId
      delete(state.projects[projectId])
      projectsRef.doc(projectId).delete()
      closeDeleteModal()
      state.currentProjectId = null
      emptyFormData()
    }

    function showAddModal() {
      state.currentProjectId = null
      modifyModal.value = true
    }

    function closeModifyModal() {
      state.currentProjectId = null
      state.formData = { ...defaultFormData }
      modifyModal.value = false
    }

    function showDeleteModal(projectId) {
      deleteModal.value = true
      state.currentProjectId = projectId
      state.formData = { ...state.projects[projectId] }
    }

    function closeDeleteModal() {
      deleteModal.value = false
      state.currentProjectId = null
      emptyFormData()
    }

    onMounted(async () => {
      const snapshot = await projectsRef.get()
      snapshot.forEach((doc) => {
        state.projects[doc.id] = doc.data()
      })
    })

    return { modifyModal, deleteModal, state, submitProjectForm, editProject, deleteProject, showAddModal, closeModifyModal, showDeleteModal, closeDeleteModal }
  }
}
</script>