<template>
  <h1 class="mb-4 text-2xl">Dw3nt Admin Login</h1>

  <div v-if="flashMessage.length" class="text-white px-6 py-4 border-0 rounded relative mb-4 dwe-bg-blue">
    <span class="inline-block align-middle mr-8">
      {{ flashMessage }} 
    </span>
    <button @click="closeFlash()" class="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-5 mr-6 outline-none focus:outline-none">
      <span>×</span>
    </button>
  </div>

  <form class="mx-6" @submit.prevent="submitLogin()">
    <div>
      <label>Email:</label>
      <input v-model="form.email" class="dwe-bg-blue rounded shadow p-2 w-full" />
    </div>
    <div>
      <label>Password:</label>
      <input type="password" v-model="form.password" class="dwe-bg-blue rounded shadow p-2 w-full" />
    </div>
    <div>
      <button class="py-1 px-4 my-2 rounded border border-white" :disabled="isLoggingIn">
        <span v-if="!isLoggingIn">Login</span>
        <span v-else>Logging in...</span>
      </button>
    </div>
  </form>
</template>

<script>
import { reactive, ref } from 'vue'
import router from '../../router/index'
import firebase from '../../utilities/firebase'

export default {
  setup() {
    const form = reactive({
      email: "",
      password: ""
    })
    const isLoggingIn = ref(false)
    const flashMessage = ref("")

    function submitLogin() {
      isLoggingIn.value = true
      firebase.auth().signInWithEmailAndPassword(form.email, form.password).then(() => {
        isLoggingIn.value = false
        form.email = ""
        form.password = ""

        router.push({ name: 'Dashboard' })
      }).catch(() => {
        isLoggingIn.value = false
        flashMessage.value = "Error logging in!"
      })
    }

    function closeFlash() {
      flashMessage.value = ""
    }

    return { form, flashMessage, isLoggingIn, submitLogin, closeFlash }
  }
}
</script>