<template>
  <SiteHeader />

  <div class="mx-6 my-10">
    <router-view />
  </div>
</template>

<script>
import SiteHeader from '../layouts/SiteHeader'
export default {
  components: { SiteHeader }
}
</script>

<style>

</style>