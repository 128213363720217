<template>
  <div class="flex justify-between items-center pb-2">
    <p>PREVIEW</p>
    <router-link
      :to="{ name: 'BlogModify', params: { postId: postId } }"
      class="mx-2 py-1 px-3 border rounded bg-yellow-600"
    >
      Edit
    </router-link>
  </div>

  <hr />

  <ViewPostComponent :postId="postId" />
</template>

<script>
import { useRoute } from 'vue-router'
import ViewPostComponent from '../../../components/ViewPostComponent'

export default {
  components: { ViewPostComponent },
  setup() {
    const route = useRoute()
    const postId = route.params.postId

    return { postId }
  }
}
</script>

<style>
#postContentWrap h1 {
  font-size: 2rem;
}

#postContentWrap h2 {
  font-size: 1.75rem;
}

#postContentWrap img {
  margin: 0 auto;
  max-height: 22rem;
}

#postContentWrap p {
  margin: 0  0 1.5rem;
}

#postContentWrap a {
  color: #94C3E3;
  text-decoration: underline;
}
</style>